import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';

import Link from 'gatsby-link';

import * as slugify from 'slugify';

import './event.scss';
import Seo from '../components/seo';

const BlogList = ({data, pageContext}) => {
  const posts = data.allMarkdownRemark.nodes;

  console.log(posts);

  const pageLinks = [];
  for (let i = 0; i < pageContext.numPages; i++) {

    pageLinks.push(i === 0 ? `/tabor` : `/tabor/${i + 1}`);
  }


  return (
    <Layout headerStyle={'waves'}>
      <Seo title={'Táborok'}/>
      <h1>Táborok</h1>
      {posts.map((post, i) => {
        const {title, description} = post.frontmatter;
        return (
          <div key={title + i} className="event-list-item">
            <Link to={`/tabor/${slugify(title, {remove: /[*+~.()'"!:@]/g, lower: true})}`}><h2>{title}</h2>
              <p>
                {description}
              </p>
            </Link>
          </div>
        );
      })}
      {pageContext.numPages > 2 && <section className="pagination">
        {pageLinks.map((link, index) =>
          index + 1 === pageContext.currentPage ?
            <span> {index + 1}</span> :
            <Link to={link} active> {index + 1} </Link>
        )
        }
      </section>}
    </Layout>
  );
};

export default BlogList;

export const blogListQuery = graphql`
    query BlogPosts($skip: Int!, $limit: Int!) {
        allMarkdownRemark(
            filter: {frontmatter: {pagename: {eq: "event"}}}
            limit: $limit
            skip: $skip
            sort: {order: DESC, fields: frontmatter___date}
        ) {
            nodes {
                frontmatter {
                    title
                    pagename
                    main
                    description
                }
            }
        }
    }

`;